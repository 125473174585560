.cart-sidebar {
  position: fixed;
  right: -300px;
  top: 0;
  width: 300px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease;
  z-index: 1000;
  padding: 0px;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Permite el scroll en la barra lateral */
}

.cart-sidebar > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Alinear el botón de cierre a la derecha */
  margin: 10px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  align-self: flex-end; /* Alinear el botón a la derecha del contenedor */
}

h2 {
  align-self: flex-start; /* Alinear el título a la izquierda del contenedor */
  margin-top: 10px; /* Espacio entre el botón y el título */
}

.cart-sidebar.open {
  right: 0;
}

.cart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.cart-items {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.cart-item {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 130px;
  width: 100%;
  border: 1px solid #ccc;
  padding: 5px;
  position: relative; /* Position relative to place the remove button */
}

.cart-item-meta{
  display: flex;
  flex-direction: column;
  width: 65%;
  height: 95%;
  margin: 2.5%;
  justify-content: center;
}

.cart-item-meta>h3,
.cart-item-meta>p {
  margin: 1px;
}

.cart-item-meta>div{
  display: flex;
  gap: 10px;
}

.cart-item-meta>div>select{
  width: 40%;
  font-size: large;
  align-self: center;
}

.item-image {
  width: 20%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
}

.remove-item-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white;
  background-color: red;
  border-radius: 50px;
  padding: 0;
  cursor: pointer;
  position: absolute;
  height: 25px;
  width: 25px;
  top: 5px;
  right: 5px;
}

@keyframes slideOutRight {
  from {
    opacity: 1;
    transform: translateX(0);
    height: 100px; 
  }
  to {
    opacity: 0;
    transform: translateX(100%);
    height: 0;
    visibility: block;
  }
} 

.fade-slide-out {
  animation: slideOutRight 0.3s forwards;
}

.checkout-btn{
  display: block;
  place-content: center;
  text-align: center;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  background-color: #475169;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  font-size: 18px;
  font-weight: bold;
}

.checkout-btn:hover{
  background-color: #626f8e;
  color: #475169;
}