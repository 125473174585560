.Catalog {
    margin: 2.5% 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Catalog > section {
    width: 100%;
}

.card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.card-image {
    width: 100%;
    height: 70%;
    object-fit: contain;
    border-bottom: 1px solid #ddd;
}

.card-info h2 {
    margin: 0;
    align-content: center;
    color: black;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* Allow text to wrap */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    max-height: 2.4em; /* 2 lines * line-height (1.2em) */
    font-size: 24px;
}

.card > a {
    height: 85%;
}

.card-name {
    height: 50%;
}

.app-screen {
    position: relative;
    top: 10vh;
}

.card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    width: 100%;
}

.card:hover {
    transform: translateY(-5px);
}

.card-title {
    margin: 0;
}

.card-metadata {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #555;
    height: 50%;
}

.card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust this to fit more columns */
    gap: 16px;
}

.card-image {
    width: auto;
    height: 65%; /* 70% of the height */
    overflow: hidden;
}

.card-image > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.card-info {
    height: 30%; /* 30% of the height */
    padding: 2.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 768px) {
    .card-container {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust this to fit more columns */
    }

    .Catalog {
        margin: 2.5%;
        width: 95%;
    }
}

.card {
    position: relative;
    overflow: hidden;
}

.out-of-stock {
    opacity: 0.5;
}

.warning-tape {
    position: absolute;
    top: 50%;
    left: -50%;
    width: 200%;
    height: 40px;
    background-color: red;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-45deg);
    transform-origin: center;
    font-weight: bold;
    z-index: 1;
    pointer-events: none;
    font-size: 16px;
}

.card-image {
    position: relative;
}

.catalog-container {
    display: flex;
}

.FilterComponent {
    width: 20%;
    min-width: 200px;
    margin: 2.5% 1%;
}

.Catalog {
    width: 80%;
    margin: 2.5% 1%;
}

/* Modal and Button Styles */
.filter-button {
    display: none;
    position: fixed;
    right: 20px;
    top: 100px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 10;
}

.filter-button>img{
    filter: invert(1);
    height: 100%;
}

.scroll-to-top {
    position: fixed;
    top: 100px; /* Adjust this value to position the button just below the header */
    left: 60%; /* Initially position at 50% of the Catalog container's width */
    transform: translateX(-50%) scale(0.9); /* Start slightly smaller */
    background-color: #475169;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition */
    z-index: 1000; /* Ensure it stays above other elements */
}

.scroll-to-top>img{
    filter: invert(1);
}

.scroll-to-top.show {
    opacity: 0.7; /* Fully visible */
    transform: translateX(-50%) scale(1); /* Return to original size */
}

.scroll-to-top:hover {
    opacity: 1;
    transform: translateX(-50%) scale(1.1); /* Slightly enlarge on hover for better visual feedback */
}

@media (max-width: 768px) {
    .filter-button {
        padding: 12px;
        padding-top: 16px;
        display: block; /* Show the filter button on mobile devices */
    }

    /* Hide the FilterComponent outside the modal on mobile devices */
    .catalog-container > .FilterComponent {
        display: none;
    }

    .catalog-container {
        flex-direction: column;
    }

    .FilterComponent, .Catalog {
        width: 100%;
        margin: 0;
    }

    /* Ensure the FilterComponent inside the modal is visible */
    .filter-modal .FilterComponent {
        display: block;
        text-align: -webkit-center;
    }
    
    .scroll-to-top{
        left: 50%;
    }
}