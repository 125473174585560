.checkout-container {
  margin: 2.5% 5%;
  font-family: 'Baskeville', serif;
}

.checkout-items {
  margin-bottom: 20px;
}

.checkout-item {
  display: flex;
  height: 200px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}
.checkout-item>div:nth-child(1),
.checkout-item>div:nth-child(2){
  align-items: center;
  height: 100%;
  width: 45%;
  text-align-last: center;
    align-content: center;
}

.checkout-item>div:nth-child(3){
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.checkout-item-amount{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px; /* Espaciado entre "Cantidad:" y el select */

}

.checkout-item-image {
  height: 100%;
  width: 90%;
  object-fit: contain;
}

.checkout-item-info p {
  text-align: center;
  margin: 0;
  font-size: 1.2em;
}

.remove-button {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.remove-button:hover {
  background-color: darkred;
}

.checkout-summary {
  text-align: right;
}

.checkout-summary h3 {
  margin: 5px 0;
}

.checkout-summary h3:nth-child(1),
.checkout-summary h3:nth-child(2),
.checkout-summary h3:nth-child(3) 
.checkout-summary h3:nth-child(4) {
  font-weight: normal;
  color: #555;
}

.checkout-summary h3:nth-child(5) {
  font-size: 1.5em;
  font-weight: bold;
  color: #000;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.checkout-button {
  background-color: #475169;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.2em;
  border-radius: 5px;
  margin-top: 20px;
}

.checkout-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.checkout-button:hover:enabled {
  background-color: #2f3e4e;
}

.order-now-button {
  background-color: #f39c12;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.2em;
  border-radius: 5px;
  margin-top: 10px;
}

.order-now-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.order-now-button:hover:enabled {
  background-color: #e67e22;
}

.toggle-resume-btn,
.toggle-address-btn {
  background-color: #475169;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 1.1em;
  border-radius: 5px;
  margin-bottom: 15px;
}

.toggle-resume-btn:hover,
.toggle-address-btn:hover {
  background-color: #2f3e4e;
}

.resume-section,
.address-section,
.payment-section {
  margin-bottom: 20px;
}

.resume-section h2,
.address-section h2,
.payment-section h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-size: 1.1em;
  display: block;
  margin-bottom: 5px;
}

.required {
  color: red;
  margin-left: 5px;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="number"] {
  width: 99%;
  padding: 0.5%;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.form-group input[type="text"]:focus,
.form-group input[type="email"]:focus,
.form-group input[type="number"]:focus {
  outline: none;
  border-color: #475169;
}

.form-group input[type="radio"] {
  margin-right: 10px;
}

.payment-section .form-group input[type="text"] {
  max-width: 200px;
}

.checkout-container > section {
  border: 1px solid #ddd;
  padding: 2.5%;
}

.address-form input {
  width: 100%;
  padding: 8px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
}

textarea{
  width: 100%;
}

.checkout-item-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alinea los elementos al inicio del contenedor */
  gap: 10px; /* Espaciado entre los elementos */
  justify-content: center;
}
.tip-section {
  display: flex;
  flex-direction: column;
  border-bottom: black 1px solid;
  padding: 20px;
  margin-right: 20px;
}

.tip-options {
  display: flex;
  gap: 10px; /* Space between buttons */
  width: 100%;
}

.tip-button {
  background-color: #f0f0f0;
  width: 25%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-top: max(10px, 2%);
  padding-bottom: max(10px, 2%);
  padding-left: min(10px, 10%);
  padding-right: min(10px, 10%);
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.tip-button:hover {
  background-color: #ececec;
  transform: translateY(-2px); /* Slight elevation on hover */
}

.tip-button.active {
  background-color: #475169;
  color: white;
  border-color: #475169;
}

.custom-tip-container {
  display: flex;
  align-items: center;
}

.custom-tip-container input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  text-align: center;
  font-size: 16px;
}

.custom-tip-container span {
  font-size: 16px;
}

.adviser-section {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.adviser-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.adviser-text {
  font-size: 18px;
  color: #555;
}

.highlight {
  color: #ff6f61;
  font-weight: bold;
}

.custom-tip-wrapper{
  text-align: center;
  display: inline-flex;
  width: 25%;
}
.custom-tip-wrapper>button{
  width: 100%;

}

@media (max-width: 1025px) {
  /* Show the custom tip input field when on mobile devices */
  .tip-button.active + .custom-tip-container {
    display: flex;
  }
  
  /* Ensure the custom tip input field is styled properly on mobile */
  .custom-tip-container {
    align-items: center;
  }

  .custom-tip-container input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    text-align: center;
    font-size: 16px;
  }

  .custom-tip-container span {
    font-size: 16px;
  }
  .custom-tip-wrapper{
    flex-direction: column;
  }
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.beer-container {
  position: relative;
  width: 96px; /* Ajusta el tamaño según el tamaño de tus imágenes */
  height: 300px;
}

.fixed-beer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2; /* Imagen 1 (fija) estará encima de la imagen 2 */
}

.loading-beer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Imagen 2 (de carga) estará debajo de la imagen 1 */
  clip-path: inset(100% 0 0 0); /* La imagen está oculta inicialmente */
  animation: revealBeer 2s ease-in-out infinite; /* La animación se repetirá infinitamente */
}

@keyframes revealBeer {
  0% {
    clip-path: inset(100% 0 0 0); /* Comienza completamente oculta */
  }
  100% {
    clip-path: inset(0 0 0 0); /* Revela completamente la imagen */
  }
}

p {
  margin-top: 20px;
  font-size: 18px;
}