/* Carousel.css */
.carousel {
    display: flex;
    align-items: center;
  }
  
  .product-name{
    color: black;
  }
  
  .carousel-container {
    overflow: hidden;
    height: 400px;
    position: relative;
  }
  
  .carousel-items {
    display: flex;
    transition: transform 0.5s ease-in-out;
    height: 100%;
  }
  
  .carousel-item {
    box-sizing: border-box;
    text-align: center;
    border: 1px solid #ccc;
  }
  
  .carousel-item > a {
    display: block;
    height: 100%;
  }
  
  .carousel-item  > img {
    width: 100%;
    height: 70%;
    object-fit: contain;
  }
  
  .product-name {
    font-size: 1.2em;
    height: 15%;
    overflow: hidden;
    align-content: center;
  }
  
  .product-image {
    width: 100px; /* Adjust based on your design */
    height: auto;
  }
  
  .product-price {
    font-size: 1em;
    font-weight: bold;
    color: green;
    height: 15%;
    align-content: center;
  }
  
  .carousel button {
    background-color: #00000033; /* Button color */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .carousel button:hover {
    background-color: #000000b1; /* Hover color */
  }
  
  .carousel button:disabled {
    background-color: #cccccc; /* Disabled color */
    cursor: not-allowed;
  }
  
  .prev-button{
    transform: rotate(180deg);
  }

  .carousel-item {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
}

.out-of-stock {
    opacity: 0.5;
}

.warning-tape {
    position: absolute;
    top: 50%;
    left: -50%;
    width: 200%;
    height: 30px;
    background-color: red;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-45deg);
    transform-origin: center;
    font-weight: bold;
    z-index: 1;
    pointer-events: none;
    font-size: 16px;
}

.carousel-item-image {
    position: relative;
}

.carousel-item {
  position: relative;
  overflow: hidden;
}

.out-of-stock {
  opacity: 0.5;
}

.warning-tape {
  position: absolute;
  top: 50%;
  left: -50%;
  width: 200%;
  height: 30px;
  background-color: red;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-45deg);
  transform-origin: center;
  font-weight: bold;
  z-index: 1;
  pointer-events: none;
  font-size: 16px;
}

.carousel-item-image {
  position: relative;
}

.react-multiple-carousel__arrow--right{
  z-index: 9;
}

.react-multiple-carousel__arrow--left{
  z-index: 9;
}