.filter-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.filter-modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    max-height: 80%;
    overflow-y: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    display: flex;
    flex-direction: column;
}

.filter-modal-content {
    padding: 20px;
    background-color: #f7f9fc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex-grow: 1;
}

.close-button-filter {
    background-color: #475169; /* Same color as Reset Filters button */
    width: 100%;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    align-self: center;
    display: block; /* Ensures it behaves like a block element */
    text-align: center;
}

.close-button:hover {
    background-color: #0056b3; /* Darker shade for hover effect */
}

.filter-modal .FilterComponent {
    display: block !important; /* Force display in modal */
}

@media (min-width: 768px) {
    .filter-modal {
        display: none;
    }
}
