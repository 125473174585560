.App-header {
  height: 10vh; /* Adjust as needed */
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11; /* Make sure header stays above the overlay */
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 100%;
  padding: 0 5%;
}

.nav {
  height: 100%;
  width: 100%;
  align-items: center;
  align-content: center;
}

.nav-left{
  width: 30%;
}

.nav-right{
  width: 15%;
}

.nav-center {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.nav-center.open {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #000;
  padding-top: 10px;
  z-index: 12;
  flex-direction: column;
}

.nav-center.open > div {
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px 0;
}

.logo-link {
  height: 100%;
  width: 100%;
  display: flex;
  font-family: 'Baskerville', serif;
  padding-bottom: 5px;
  justify-content: space-around;
}

.logo{
  margin: 0;
  padding: 1% 0;
  width: auto;
  height: 90%;
  object-fit: contain;
}

.logo-name{
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: white;
  padding: 10px;
}

.menu-toggle:focus {
  outline: none;
}

.nav-right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  padding: 0 2.5%;
}

.search-bar {
  all: unset;
  padding: 0 10%;
  width: 70%;
  height: 80%;
  font-size: 18px;
  box-shadow: 0 2px 4px rgba(44, 62, 80, 0.5);
  background-color: #475169;
  border-radius: 100px;
  width: 100%; /* Optional: Set width to the input if needed */
  max-width: 300px; /* Optional: Limit the maximum width of the input */
}

.App-header p {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}

a {
  color: white;
  text-decoration: none;
  position: relative;
}

a.active p {
  color: #d1d8e0;
}

a.active::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background-color: #d1d8e0;
  border-radius: 2px;
  transform: scaleX(1);
  transition: transform 0.3s ease;
}

a::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

a:hover::after {
  transform: scaleX(1);
}

@media (max-width: 1025px) {
  .App-header {
    flex-direction: column;
    align-items: center;
    height: 10vh;
  }
  
  .nav-left {
    width: auto;
    object-fit: contain;
  }
  
  .nav-center {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    height: 30vh;
  }
  
  .nav-center.open {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .nav-center > div {
    width: 100%;
    padding: 10px;
    text-align: left;
  }
  
  .nav-right {
    width: 80%;
    padding: 10px;
  }

  .nav-left{
    width: 20%;
    display: flex;
    align-items: center; /* This centers the menu-toggle button vertically */
  }

  .menu-toggle {
    display: block;
  }

  a.active::after{
    background-color: #000;
  }

  .logo-link{
    display: none;
  }
  
  .nav-left{
    width: 45%;
  }

  .nav-left>button{
    height: 100%;
  }

  .nav-left>button:nth-child(2){
    width: auto;
  }
  .nav-left>button:nth-child(3){
    width: 50%;
  }

  .nav-left>button>a>img{
    height: 100%;
  }

  .nav-right{
    width: 55%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10; /* Ensure it appears above everything else */
  display: none;
}

.overlay.visible {
  display: block;
}

.cart {
  position: relative;
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart > button {
  position: relative;
  width: 60%;
  height: 60%;
  padding: 5%;
  border: 0;
  border-radius: 100px;
}

.cart>button:hover {
  background-color: #475169;
}

.cart>button:active{
  background-color: #2f3e4e;
}

.cart > button > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cart-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}
