/* ContactUs.css */

.visit-us,
.store-hours,
.contact-us,
.get-in-touch,
.contact-form,
.social-media{
    text-align: center;
}

.contact-us {
    padding: 0 40px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: #f9f9f9;
    color: #333;
  }
  
  .contact-us h1 {
    color: #2c3e50;
    font-size: 42px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
  }
  
  .contact-us p {
    color: #7f8c8d;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .contact-us section {
    margin-bottom: 40px;
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .contact-us h2 {
    color: #34495e;
    font-size: 32px;
    margin-bottom: 15px;
    border-bottom: 2px solid #ecf0f1;
    padding-bottom: 10px;
  }
  
  .contact-us address,
  .contact-us ul {
    color: #7f8c8d;
    font-size: 18px;
    line-height: 1.6;
  }
  
  .contact-us address a {
    color: #2980b9;
    text-decoration: none;
  }
  
  .contact-us address a:hover {
    text-decoration: underline;
  }
  
  .contact-us .store-hours ul,
  .contact-us .social-media ul,
  .contact-us .delivery-partners ul {
    list-style-type: none;
    padding: 0;
  }
  
  .contact-us .store-hours ul li,
  .contact-us .social-media ul li,
  .contact-us .delivery-partners ul li {
    margin-bottom: 10px;
  }
  
  .contact-us .store-hours strong,
  .contact-us .social-media strong,
  .contact-us .delivery-partners strong {
    color: #34495e;
  }
  
  .contact-us .contact-form {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .contact-us .contact-form div {
    margin-bottom: 20px;
  }
  
  .contact-us .contact-form label {
    display: block;
    color: #34495e;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .contact-us .contact-form input[type="text"],
  .contact-us .contact-form input[type="email"],
  .contact-us .contact-form textarea {
    width: 90%;
    padding: 5%;
    border: 1px solid #bdc3c7;
    border-radius: 5px;
    font-size: 16px;
    color: #34495e;
    background-color: #ecf0f1;
  }
  
  .contact-us .contact-form textarea {
    height: 120px;
    resize: vertical;
  }
  
  .contact-us .contact-form button[type="submit"] {
    background-color: #2980b9;
    color: #fff;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-us .contact-form button[type="submit"]:hover {
    background-color: #1e6a92;
  }
  
  .contact-us .social-media ul li a {
    color: #2980b9;
    text-decoration: none;
  }
  
  .contact-us .social-media ul li a:hover {
    text-decoration: underline;
  }
  
  .app-screen{
    position: relative;
    top: 10vh;
}