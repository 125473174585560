.loading-screen {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.beer-container {
    position: relative;
    width: 150px; /* Ajusta el tamaño según el tamaño de tus imágenes */
    height: 300px;
}

.fixed-beer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; /* Imagen 1 (fija) estará encima de la imagen 2 */
}

.loading-beer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Imagen 2 (de carga) estará debajo de la imagen 1 */
    clip-path: inset(100% 0 0 0); /* La imagen está oculta inicialmente */
    animation: revealBeer 2s ease-in-out infinite; /* La animación se repetirá indefinidamente */
}

@keyframes revealBeer {
    0% {
        clip-path: inset(100% 0 0 0); /* Comienza completamente oculta */
    }
    100% {
        clip-path: inset(0 0 0 0); /* Revela completamente la imagen */
    }
}