.ReactModal__Overlay{
    z-index: 1000;
    color: white; 
    background-color: rgba(0, 0, 0, 1) !important;
}

.ReactModal__Overlay>div{
    align-content: center;
    text-align: -webkit-center;
    background: rgb(63 63 63) !important;
}

/* AgeVerificationModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px); /* Optional: Adds a blur effect to the background */
}

.modal-content {
    background-color: #000;
    border: 1px solid #222;    
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 500px;
    padding: 100px 20px;
    text-align: center;
    font-family: 'Baskerville', serif;
}

.modal-content p {
    font-size: 1.5em;
    color: black;
    margin-bottom: 20px;
}

.modal-content button {
    margin: 5px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    background-color: #000;
    color: white;
    font-family: 'Baskerville', serif;
}

.modal-content button:hover {
    background-color: #444;
}

button:focus {
    outline: none;
}

.modal-over-age{
    text-align: -webkit-center;
    width: 100%;
    height: 100%;
    border: 1px solid #222;
    background-color: #292929;
    align-content: center;
}

.modal-over-age>.image{
    width: 40%;
    height: 60%;
}

.modal-over-age>div>img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    
}

@media (max-width: 768px) {
    .modal-content {
        width: 90%;
        padding: 10px;
    }

    .modal-content p {
        font-size: 1.2em;
    }

    .modal-content button {
        padding: 8px 16px;
        font-size: 14px;
    }

    .modal-over-age>.image{
        width: 100%;
    }

    .modal-over-age{
        text-align: -webkit-center;
        padding: 10px;
        height: 95%;
        width: 95%;
    }
}
