/* src/layout/FilterComponent.css */
.FilterComponent {
  display: flex;
  flex-direction: column;
  background-color: #f7f9fc;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 250px;
}

.FilterComponent div {
  margin-bottom: 15px;
}

.FilterComponent label {
  font-size: 16px;
  font-weight: bold;
  color: #2c3e50;
  display: block;
  margin-bottom: 5px;
}

.FilterComponent select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccd1d9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transition: border-color 0.2s;
}

.FilterComponent select:focus {
  border-color: #007bff;
  outline: none;
}

@media (max-width: 768px) {
  .FilterComponent {
      margin: 0;
      padding: 2.5%;
      width: 100%;
      max-width: none;
  }
}

.reset-button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: #475169;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  font-size: 16px;
  font-weight: bold;
}

.reset-button:hover {
  background-color: white;
  color: #475169;
  border-color: #475169;
}

.filter-modal {
  z-index: 10000; /* Ensure it's on top */
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}
